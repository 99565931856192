const jsSha3 = require('js-sha3');
import {Helpers} from "../services/Helpers";

let currentHashFunction = jsSha3.keccak256;

export default {
    name: 'hashes',
    template: require('./Hashes.html'),
    data() {
        return {
            input: '',
            output: '',
            output8: '',
            functionName: 'keccak_256',
            link: ''
        }
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'hash') {
            this.input = this.$parseQuery.get('hash-value');
            this.functionName = this.$parseQuery.get('hash-function') || 'keccak_256';
        }

        this.recalculateHash();
    },
    methods: {
        changeHashFunction(name) {
            currentHashFunction = jsSha3[name];
            this.functionName = name;
            this.recalculateHash();
        },
        recalculateHash() {
            const hash = currentHashFunction(this.input);
            this.output = hash;
            this.output8 = hash.substr(0, 8);
            this.link = window.location.origin
                + "/?target=hash&hash-function="
                + this.functionName
                + '&hash-value='
                + this.input
                + "#bp-hash";
        },
        copy() {
            Helpers.copyToClipboard(this.output);
        },
        copy8() {
            Helpers.copyToClipboard(this.output8);
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}