import AddressFormatter from "./components/AddressFormatter/AddressFormatter";
import UnitConverter from "./components/UnitConverter/UnitConverter";
import KeysPipeline from "./components/KeysPipeline/KeysPipeline";
import CliMappings from "./components/CliMappings/CliMappings";
import EcRecover from "./components/EcRecover/EcRecover";
import EvmWords from "./components/EvmWords/EvmWords";
import Hashes from "./components/Hashes/Hashes";
import Hex from "./components/Hex/Hex";
import VueWorker from 'vue-worker';
import Vuelidate from 'vuelidate';
import Vue from 'vue';

import parseQuery from "./plugins/parseQuery.plugin";

Vue.use(Vuelidate);
Vue.use(VueWorker);
Vue.use(VueWorker);

Vue.use(parseQuery);

export default {
    name: 'app',
    template: require('./App.html'),
    components: { KeysPipeline, Hashes, Hex, UnitConverter, AddressFormatter, CliMappings, EvmWords, EcRecover }
}
