import {Helpers} from "../services/Helpers";

const Web3Utils = require('web3-utils');


window['utils'] = Web3Utils;

function hexToWord(hexInput) {
    return Web3Utils.padRight(hexInput, '64');
}

function validEvmWord(value) {
    if (value.length === 64 && Web3Utils.isHex(value)) {
        return true;
    } else if (value.length === 66 && Web3Utils.isHexStrict(value)) {
        return true;
    } else {
        return false;
    }
}

function validHex32(value) {
    if (value === '') {
        return true;
    } else if (value.length <= 64 && Web3Utils.isHex(value)) {
        return true;
    } else if (value.length <= 66 && Web3Utils.isHexStrict(value)) {
        return true;
    } else {
        return false;
    }
}


export default {
    name: 'evm-words',
    template: require('./EvmWords.html'),
    data() {
        return {
            evm: '',
            hex: '',
            number: '',
            ascii: '',
            utf8: '',
            utf8Valid: true,
            bytes: ''
        };
    },
    validations: {
        hex: {
            validHex32: validHex32
        },
        evm: {
            validWord: validEvmWord
        },
        number: {
            validHex: function(value) {
                return validHex32(Web3Utils.numberToHex(value));
            }
        },
        utf8: {
            validHex: function(value) {
                return validHex32(Web3Utils.utf8ToHex(value));
            }
        },
        ascii: {
            validHex: function(value) {
                return validHex32(Web3Utils.asciiToHex(value));
            }
        }
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'evm-words') {
            this.evm = this.$parseQuery.get('evm-value');
            this.evmChanged();
        } else {
            this.number = '42';
            this.numberChanged();
        }
    },
    methods: {
        updateLink() {
            this.link = window.location.origin
                + "/?target=evm-words&evm-value="
                + this.evm
                + "#bp-evm-words";
        },
        setUtf8(hex) {
            try {
                this.utf8 = Web3Utils.hexToUtf8(hex);
                this.utf8Valid = true;
            } catch (e) {
                this.utf8 = '';
                this.utf8Valid = false;
            }
        },
        evmChanged() {
            const evm = this.evm;

            if (!validEvmWord(evm)) {
                return;
            }

            this.hex = evm;
            this.ascii = Web3Utils.hexToAscii(evm);
            this.number = Web3Utils.hexToNumberString(evm);
            this.bytes = Web3Utils.hexToBytes(evm);
            this.setUtf8(evm);
            this.updateLink();
        },
        hexChanged() {
            if (!validHex32(this.hex)) {
                return;
            }

            const evm = hexToWord(this.hex);

            this.evm = evm;
            this.ascii = Web3Utils.hexToAscii(evm);
            this.number = Web3Utils.hexToNumberString(evm);
            this.bytes = Web3Utils.hexToBytes(evm);
            this.setUtf8(evm);
            this.updateLink();
        },
        numberChanged() {
            const hex = Web3Utils.numberToHex(this.number);

            if (!validHex32(hex)) {
                return;
            }

            const evm = Web3Utils.padLeft(hex, 64);

            this.evm = evm;
            this.hex = evm;
            this.ascii = Web3Utils.hexToAscii(hex);
            this.bytes = Web3Utils.hexToBytes(hex);
            this.setUtf8(hex);
            this.updateLink();
        },
        asciiChanged() {
            const hex = Web3Utils.asciiToHex(this.ascii);

            if (!validHex32(hex)) {
                return;
            }

            const evm = hexToWord(hex);

            this.evm = evm;
            this.hex = evm;
            this.number = Web3Utils.hexToNumberString(evm);
            this.bytes = Web3Utils.hexToBytes(evm);
            this.setUtf8(evm);
            this.updateLink();
        },
        utf8Changed() {
            const hex = Web3Utils.utf8ToHex(this.utf8);

            if (!validHex32(hex)) {
                return;
            }

            const evm = hexToWord(hex);

            this.evm = evm;
            this.hex = evm;
            this.ascii = Web3Utils.hexToAscii(evm);
            this.number = Web3Utils.hexToNumberString(evm);
            this.bytes = Web3Utils.hexToBytes(evm);
            this.updateLink();
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}