import {Helpers} from "../services/Helpers";

const Web3Utils = require('web3-utils');
const utils = require('ethereumjs-utils');

export default {
    name: 'ec-recover',
    template: require('./EcRecover.html'),
    data() {
        return {
            input: '',
            signature: '',
            address: '',
            link: ''
        }
    },
    validations: {
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'ec-recover') {
            this.input = this.$parseQuery.get('ec-recover-input');
            this.signature = this.$parseQuery.get('ec-recover-signature');
        }

        this.process();
    },
    methods: {
        async process() {
            if (this.input !== "" && this.signature !== "") {
                try {
                    const parsed = this.signature.substr(2); //remove 0x
                    const r = '0x' + parsed.slice(0, 64);
                    const s = '0x' + parsed.slice(64, 128);
                    const v = '0x' + parsed.slice(128, 130);
                    const v_decimal = Web3Utils.hexToNumber(v);

                    const msg = utils.hashPersonalMessage(Buffer.from(this.input));
                    const pubKey = utils.ecrecover(msg, v_decimal, r, s);
                    this.address = "0x" + utils.pubToAddress(pubKey).toString('hex');
                } catch (e) {
                    console.log('error', e);
                    this.address = "";
                }
            } else {
                this.address = "";
            }

            this.link = window.location.origin
                + "/?target=ec-recover&ec-recover-signature="
                + this.signature
                + "&ec-recover-input="
                + this.input
                + "#bp-ec-recover";
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}