import {Helpers} from "../services/Helpers";

const Web3Utils = require('web3-utils');


window['utils'] = Web3Utils;


export default {
    name: 'hex',
    template: require('./Hex.html'),
    data() {
        return {
            hex: '',
            number: '',
            ascii: '',
            utf8: '',
            utf8Valid: true,
            bytes: '',
            link: ''
        };
    },
    validations: {
        hex: {
            isHexStrict: Web3Utils.isHexStrict
        },
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'hex') {
            this.hex = this.$parseQuery.get('hex-value');
            this.hexChanged();
        } else {
            this.number = '42';
            this.numberChanged();
        }
    },
    methods: {
        updateLink() {
            this.link = window.location.origin
                + "/?target=hex&hex-value="
                + this.hex
                + "#bp-hex";
        },
        setUtf8(hex) {
            try {
                this.utf8 = Web3Utils.hexToUtf8(hex);
                this.utf8Valid = true;
            } catch (e) {
                this.utf8 = '';
                this.utf8Valid = false;
            }
        },
        hexChanged() {
            const hex = this.hex;

            if (!Web3Utils.isHexStrict(hex)) {
                return;
            }

            this.ascii = Web3Utils.hexToAscii(hex);
            this.number = Web3Utils.hexToNumberString(hex);
            this.bytes = Web3Utils.hexToBytes(hex);
            this.setUtf8(hex);
            this.updateLink();
        },
        numberChanged() {
            const hex = Web3Utils.numberToHex(this.number);
            this.hex = hex;
            this.ascii = Web3Utils.hexToAscii(hex);
            this.bytes = Web3Utils.hexToBytes(hex);
            this.setUtf8(hex);
            this.updateLink();
        },
        asciiChanged() {
            const hex = Web3Utils.asciiToHex(this.ascii);

            this.hex = hex;
            this.number = Web3Utils.hexToNumberString(hex);
            this.bytes = Web3Utils.hexToBytes(hex);
            this.setUtf8(hex);
            this.updateLink();
        },
        utf8Changed() {
            const hex = Web3Utils.utf8ToHex(this.utf8);

            this.hex = hex;
            this.number = Web3Utils.hexToNumberString(hex);
            this.ascii = Web3Utils.hexToAscii(hex);
            this.bytes = Web3Utils.hexToBytes(hex);
            this.updateLink();
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}