import {Helpers} from "../services/Helpers";

const Web3Utils = require('web3-utils');

export default {
    name: 'address-formatter',
    template: require('./AddressFormatter.html'),
    data() {
        return {
            address: '',
            checksumAddress: '',
            lowercased: '',
            hex: '',
            link: ''
        }
    },
    validations: {
        address: {
            isAddress(value) {
                return value === '' || Web3Utils.isAddress(value);
            }
        }
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'address') {
            this.address = this.$parseQuery.get('address');
        }

        this.process();
    },
    methods: {
        process() {
            const address = this.address;
            if (Web3Utils.isAddress(address)) {
                this.checksumAddress = Web3Utils.toChecksumAddress(address);
                this.hex = Web3Utils.padLeft(address, 64);
                this.lowercased = this.checksumAddress.toLowerCase();
            } else {
                this.checksumAddress = '';
                this.hex = '';
                this.lowercased = '';
            }

            this.link = window.location.origin
                + "/?target=address&address="
                + this.address
                + "#bp-address";
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}