const jsSha3 = require('js-sha3');
const Web3 = require('web3');
const Wallet = require('ethereumjs-wallet');
const BigNumber = require('bignumber.js');
const BN = require('bn.js');
const bip39 = require('bip39');


export default {
    name: 'cli-mappings',
    template: require('./CliMappings.html'),
    mounted() {
        // web3 0.x
        global['web30'] = global['web3'];

        // web3 1.x
        // https://web3js.readthedocs.io/en/1.0/index.html
        const injectedWeb3 = global['web3'];
        if (!injectedWeb3) {
            console.warn("No injected web3 provider found")
        }
        const web3 = new Web3((injectedWeb3 && global['web3'].currentProvider) || Web3.givenProvider);
        global['Web30'] = Web3;
        global['web31'] = web3;
        global['web3'] = web3;

        // js-sha3
        // https://github.com/emn178/js-sha3
        global['jsSha3'] = jsSha3;
        global['jsSha3'] = jsSha3;

        // ethereumjs-wallet
        // https://github.com/ethereumjs/ethereumjs-wallet
        global['Wallet'] = Wallet;

        // Buffer
        // https://github.com/feross/safe-buffer
        global['Buffer'] = Buffer;

        // bip39
        // https://github.com/bitcoinjs/bip39
        global['bip39'] = bip39;

        // BigNumber
        // https://github.com/MikeMcl/bignumber.js/
        global['BigNumber'] = BigNumber;

        // BN
        // https://github.com/indutny/bn.js/
        global['BN'] = BN;
    }
}