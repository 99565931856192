const Wallet = require('ethereumjs-wallet');
import {Helpers} from "../services/Helpers";
import {V3Worker} from '../../workers';
import { RadarSpinner  } from 'epic-spinners'

const hexRegex = new RegExp("^0[xX][0-9a-fA-F]+$");
const privateKeyRegex = new RegExp("^0[xX][0-9a-fA-F]+$");


export default {
    name: 'keys-pipeline',
    template: require('./KeysPipeline.html'),
    components: { RadarSpinner },
    data() {
        return {
            privateKey: '',
            publicKey: '',
            address: '',
            lastPrivateKey: '',
            passphrase: '',
            json: '',
            jsonFileName: '',
            privateKeyValid: false,
            jsonValid: false,
            jsonWorking: false,
            privateKeyError: '',
            publicKeyErrors: [],
            addressErrors: [],
            walletInstance: null,
            v3Worker: null
        }
    },
    methods: {
        generateRandomPrivate: function() {
            this.walletInstance = window['currentWallet'] = Wallet.generate();
            console.info('Wallet instance (also available as `window.currentWallet`):', this.walletInstance);
            this.privateKeyValid = true;
            this.privateKeyError = '';
            this.jsonValid = false;
            this.json = '';
            this.privateKey = this.walletInstance.getPrivateKeyString();
            this.publicKey = this.walletInstance.getPublicKeyString();
            this.address = this.walletInstance.getAddressString();
        },
        privateKeyChanged: function() {
            this.jsonValid = false;
            this.json = '';

            let p = this.privateKey;

            if (this.lastPrivateKey === p) {
                return;
            }
            this.lastPrivateKey = p;
            this.privateKeyError = '';

            if (p.length === 66) {
                p = p.substr(2);
            }

            this.clearFromPrivate();

            if (p.length < 64) {
                this.privateKeyValid = false;
                this.privateKeyError = "Error: Private key too small, it should contain 64 symbols (66 if starting with 0x)";
                return;
            }

            if (p.length == 65 || p.length > 66) {
                this.privateKeyValid = false;
                this.privateKeyError = "Error: Private key too big, it should contain 64 symbols (66 if starting with 0x)";
                return;
            }

            try {
                this.walletInstance = window['currentWallet'] = Wallet.fromPrivateKey(Buffer.from(p, 'hex'));
            } catch (e) {
                this.privateKeyValid = false;
                this.privateKeyError = e.toString();
                return;
            }

            console.info('Wallet instance (also available as `window.wallet`):', this.walletInstance);

            this.privateKeyValid = true;

            this.privateKey = this.walletInstance.getPrivateKeyString();
            this.publicKey = this.walletInstance.getPublicKeyString();
            this.address = this.walletInstance.getAddressString();
        },
        generateV3: function() {
            this.jsonWorking = true;
            this.json = 'Generating...';
            this.v3Worker.postMessage({ data: [this.privateKey.substr(2), this.passphrase] });
        },
        donwloadJson: function() {
            Helpers.downloadJson(this.jsonFileName, this.json);
        },
        copyAddress: function() {
            Helpers.copyToClipboard(this.address);
        },
        copyJson: function() {
            Helpers.copyToClipboard(this.json);
        },
        clearFromPrivate: function() {
            this.publicKey = '';
            this.address = '';
        }
    },
    mounted() {
        this.v3Worker = new V3Worker();
        this.v3Worker.onmessage = (e) => {
            if (e.data.json.length > 0) {
                this.json = e.data.json;
                this.jsonFileName = e.data.name;
                this.jsonWorking = false;
                this.jsonValid = true;
            }
        }
    }
}