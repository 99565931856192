const query = {};
let parsed = false;

export default class ParseQuery {
    static get(key) {
        if (!parsed) {
            const queryString = window.location.search;

            const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
            for (let i = 0; i < pairs.length; i++) {
                const pair = pairs[i].split('=');
                query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }

            parsed = true;
        }

        return query[key];
    }
}