const BigNubmer = require('bignumber.js');

import {Helpers} from "../services/Helpers";


const zero = new BigNubmer('0');
const one = new BigNubmer('1');
const Wei = new BigNubmer('1');
const Kwei = new BigNubmer('1000');
const Mwei = new BigNubmer('1000000');
const Gwei = new BigNubmer('1000000000');
const Szabo = new BigNubmer('1000000000000');
const Finney = new BigNubmer('1000000000000000');
const Ether  = new BigNubmer('1000000000000000000');
const Kether = new BigNubmer('1000000000000000000000');
const Mether = new BigNubmer('1000000000000000000000000');
const Gether = new BigNubmer('1000000000000000000000000000');
const Tether = new BigNubmer('1000000000000000000000000000000');

function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
    name: 'unit-converter',
    template: require('./UnitConverter.html'),
    data() {
        return {
            denominations: {
                wei: { name: 'wei', description: '1', value: '' },
                kwei: { name: 'kwei', description: '1e3', value: '' },
                mwei: { name: 'mwei', description: '1e6', value: '' },
                gwei: { name: 'gwei', description: '1e9', value: '' },
                szabo: { name: 'szabo', description: '1e12', value: '' },
                finney: { name: 'finney', description: '1e15', value: '' },
                ether: { name: 'ether', description: '1e18', value: '' },
            },
            constants: {
                zero,
                one,
                Wei,
                Kwei,
                Mwei,
                Gwei,
                Szabo,
                Finney,
                Ether
            },
            link: ''
        }
    },
    created() {
        const target = this.$parseQuery.get('target');

        if (target === 'unit-converter') {
            const wei = this.$parseQuery.get('unit-converter-wei');

            this.denominations.wei.value = wei;
            this.changed(new BigNubmer(wei), 'wei');
        } else {
            this.denominations.ether.value = '1';
            this.changed(new BigNubmer('1'), 'ether');
        }
    },
    methods: {
        renderValue(input, decimals, currentDecimals) {
            const v = input.div(this.constants[cap(decimals)]);

            if (currentDecimals !== decimals) {
                this.denominations[decimals].value = v.toString(10);
            }
            this.denominations[decimals].exponential = v.toExponential();
            if (v.isInteger()) {
                this.denominations[decimals].hex = `0x${v.toString(16)}`;
            } else {
                this.denominations[decimals].hex = ``;
            }
        },
        changed(i, decimals) {
            const v = i.multipliedBy(this.constants[cap(decimals)]);

            this.renderValue(v, 'wei', decimals);
            this.renderValue(v, 'kwei', decimals);
            this.renderValue(v, 'mwei', decimals);
            this.renderValue(v, 'gwei', decimals);
            this.renderValue(v, 'szabo', decimals);
            this.renderValue(v, 'finney', decimals);
            this.renderValue(v, 'ether', decimals);

            this.link = window.location.origin
                + "/?target=unit-converter&unit-converter-wei="
                + this.denominations.wei.value
                + "#bp-unit-converter";
        },
        handleChanged(name) {
            const d = this.denominations[name];
            if (d.last === d.value) return;
            d.last = d.value;
            this.changed(new BigNubmer(d.value), name);
        },
        copyLink(e) {
            e.preventDefault();

            console.log('🔗', this.link);

            Helpers.copyToClipboard(this.link);
        }
    }
}
